import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

const routes = [
    // {
    //     path: "/",
    //     name: "index",
    //     component: () => import("@/pages/index.vue"),
    // },
    {
        path: "/",
        name: "home",
        component: () => import("@/pages/home.vue"),
    },
    {
        path: "/home",
        name: "home",
        component: () => import("@/pages/home.vue"),
    }
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});
export default router